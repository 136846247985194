<template>
  <v-container>
    <v-row class="row--35">
      <v-col lg="6" md="6" order-md="1" cols="12" style="padding-bottom: 10px">
        <v-img
          class="w-100"
          src="../assets/images/stockpics/healthcare_small/healthcare_003.jpg"
          alt="About Images"
          aspect-ratio="0.8"
        />
      </v-col>
      <v-col lg="6" md="6" order-md="2" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title">
              <span class="icon" v-html="iconSvg(icon1)"></span>About us
            </h2>
            <p class="description">
              At RHS24 Care we provide quality personal care and support to
              adults to live independently in the comfort of their own homes.
              Our recruitment section operates in the Health and Social Care
              Sectors serving the UK's Midlands region and beyond. We provide
              temporary and permanent recruitment solutions to a broad range of
              health and social care clients throughout the Midlands and beyond.
            </p>
            <p
              data-aos="slide-left"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              class="description"
            >
              We work closely with our clients to fully understand their
              specific needs, which helps us provide them with an unrivalled
              service. We are committed to finding our candidates' roles to suit
              their availability and match their skills to their job roles to
              ensure that businesses and candidates flourish.
            </p>
            <p
              data-aos="zoom-in"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              class="description"
            >
              RHS24 Ltd is REC accredited and adheres to the REC Code of
              Professional Practice, demonstrating our commitment to
              professional and ethical recruitment. Delivering Quality and
              Excellence to all Service Users is the driving force behind the
              birth of RHS24 Ltd.
            </p>
            <!-- <p
              data-aos="slide-left"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              class="description"
            >
              At RHS24 Ltd, we are committed to finding our candidates roles to
              suit their availability and match their skills to their job roles
              to ensure businesses and candidates flourish. Our candidates enjoy
              competitive rates of pay.
            </p> -->
          </div>
        </div>
      </v-col>
      <v-col lg="6" md="6" order-md="4" cols="12">
        <v-img
          style="margin-top: 10px; margin-bottom: 10px"
          src="../assets/images/stockpics/healthcare_small/healthcare_007.jpg"
          alt="About Images"
          aspect-ratio="0.91"
        />
      </v-col>
      <v-col lg="6" md="6" order-md="3" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title">
              <span class="icon" v-html="iconSvg(icon2)"></span>Why work with us
            </h2>
            <p class="description">
              We specialise in placing nurses and care workers into agency
              nursing jobs across NHS boards and private healthcare providers
              throughout the UK's midlands area. We offer a wide range of
              flexible, temporary and contract opportunities in specialist
              fields such as adult nursing, mental health nursing, theatre work,
              critical care and care homes.
            </p>
            <p
              data-aos="slide-right"
              data-aos-easing="ease"
              data-aos-duration="500"
              class="description"
            >
              As experts in recruitment, we offer unrivalled services and access
              to the top employers and talent. We will support your skills
              development and help you to navigate your career in the healthcare
              industry.
            </p>
            <!-- <p
              data-aos="fade"
              data-aos-easing="ease"
              data-aos-duration="500"
              class="description"
            >
              We are the experts in recruiting qualified, professional and
              skilled people in the healthcare sector. We work with the NHS and
              private healthcare providers, dealing in permanent positions,
              contract roles and temporary assignments. We are dedicated to
              connecting right people with the right job.​
            </p> -->
          </div>
        </div>
      </v-col>
      <v-col lg="6" md="6" order-md="5" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <p class="description">
              When you join us, you become part of our extended family. We work
              passionately for your career development and connect you to the
              right job for you. You will benefit from our specialist healthcare
              sector knowledge and industry contacts.
            </p>
            <p class="description">
              At RHS24 Ltd, we believe training and development is very
              important. That's why we offer our staff a vast range of
              opportunities to learn and progress. Through a diverse range of
              employee benefits we help bring people and businesses together as
              the right job for the right individual can set their career for
              life.
            </p>
          </div>
        </div>
      </v-col>
      <v-col lg="6" md="6" order-md="6" cols="12">
        <ul
          data-aos="zoom-in-down"
          data-aos-easing="ease"
          data-aos-duration="500"
          class="list-style--1"
          style="margin-top: 10px"
        >
          <li class="d-flex" v-for="(item, i) in lstOpportunities" :key="i">
            <i v-html="iconSvg(item.icon)"></i>
            {{ item.desc }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      icon1: "file-text",
      icon2: "smile",
      lstOpportunities: [
        {
          icon: "check",
          desc: `Competitive Pay Rates`,
        },
        {
          icon: "check",
          desc: `As many shifts as you want`,
        },
        {
          icon: "check",
          desc: `Flexible Shift Patterns/ take ownership of your schedule`,
        },
        {
          icon: "check",
          desc: `Our incredible managers will support and help you in every and any way we can! We want you to succeed`,
        },
        {
          icon: "check",
          desc: `A great company is only great because of the amazing staff behind it! be part of the team and grow together`,
        },
        {
          icon: "check",
          desc: `Career Progression Opportunities`,
        },
        {
          icon: "check",
          desc: `Diverse Experience`,
        },
        {
          icon: "check",
          desc: `Permanent Placements`,
        },
        {
          icon: "check",
          desc: `Up to date training and coaching`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style lang="scss" scoped>
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1263px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
.about-inner {
  // @media #{$md-layout} {
  //     padding-top: 40px;
  // }
  // @media #{$sm-layout} {
  //     padding-top: 40px;
  // }
  .icon {
    width: 60px;
    height: 60px;
    @media #{$sm-layout} {
      width: 45px;
      height: 45px;
      line-height: 55px;
    }
    background: var(--color-primary);
    border-radius: 100%;
    text-align: center;
    color: #fff;
    margin-bottom: 17px;
    padding-right: 3px;
    display: inline-block;
    //display: flex;
    // align-items: center;
    // justify-content: center;
    svg {
      stroke-width: 1;
      width: 27px;
      height: 27px;
    }
  }
  .section-title {
    h2 {
      &.heading-title {
        // line-height: 1.3;
        margin-bottom: 0;
      }
      span {
        margin-right: 7px;
      }
    }
    p {
      &.description {
        color: var(--color-gray);
        a {
          color: rgba(29, 29, 36, 1);
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }
}
/* .app-video-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: block;
  position: relative;
}
.app-video-wrapper .app-video-file {
  width: 100%;
  height: 100%;
}
.app-video-wrapper .app-video-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
} */
</style>

<template>
  <div class="about-area ptb--60 bg_color--1">
    <div class="about-wrapper">
      <AboutUs>
        <img
          slot="thum-img"
          class="w-100"
          src="../assets/images/others/about-1.png"
          alt="About Images"
        />       
      </AboutUs>
    </div>
  </div>
</template>
<script>
import AboutUs from "../components/AboutUs";
export default {
  components: {
    AboutUs,
  },
};
</script>
